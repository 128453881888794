<main class="layout-column">
  <div id="mySpinner" *ngIf="showLoadingIndicator" class="overlay">
    <div class="center">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="layout-row">
    <h1 class="flex header-text">Inventory</h1>
  </div>
  <div class="layout-row chips">
    <app-filter-chip-list *ngIf="inventoryItems"></app-filter-chip-list>
    <div class="filter-button" id="filter_button">
      <button mat-button (click)="drawer.toggle()" *ngIf="drawer.opened; else FilterButton">
        <span>CLOSE</span>
        <mat-icon svgIcon="vsp-icons:close" iconPositionEnd></mat-icon>
      </button>
      <ng-template #FilterButton>
        <button *ngIf="inventoryItems" mat-button (click)="drawer.toggle()">
          <span>FILTER</span>
          <mat-icon svgIcon="vsp-icons:filter" iconPositionEnd></mat-icon>
        </button>
      </ng-template>
    </div>
  </div>

  <mat-drawer-container class="layout-column" hasBackdrop="false">
    <mat-drawer class="sidebar" position="end" [autoFocus]="false" #drawer>
      <search-bar class="layout-column" *ngIf="inventoryItems"></search-bar>
    </mat-drawer>
    <mat-drawer-content class="layout-column" *ngIf="inventoryItems">
      <panel-bar *ngFor="let item of displayInventoryItems; let odd = odd; let even = even" [inventoryItem]="item"
                 [ngClass]="{ odd: odd, even: even }"></panel-bar>
    </mat-drawer-content>
  </mat-drawer-container>

  <mat-paginator [length]="getInventoryItemsLength()"
                 [pageSize]="pageSize"
                 [showFirstLastButtons]="true"
                 [pageSizeOptions]="pageSizeOptions"
                 (page)="changePage($event)">
  </mat-paginator>
</main>
