import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ItemPropertiesComponent} from './itemProperties.component';
import { MaterialsModule } from 'src/app/materials.module';


@NgModule({
  imports: [CommonModule, FormsModule, MaterialsModule],
  declarations: [ItemPropertiesComponent],
  exports: [ItemPropertiesComponent]
})

export class ItemPropertiesModule {}
